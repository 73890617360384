<template>
  <svg
    id="RGB_Logo_Mørkeblå"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 300.3 154"
    style="enable-background: new 0 0 300.3 154"
    xml:space="preserve"
  >
    <path
      class="oslo-logo"
      d="M102.6,34.2l-2.1-1.3c-3.2-2.1-6.6-3.9-10.2-5.4c-1.5-0.6-3-1.2-4.5-1.7c-3.6-1.2-7.4-2.2-11.1-2.8
	c-1.6-0.3-3.2-0.4-4.9-0.6c-3.8-0.3-7.7-0.3-11.5,0c-1.6,0.1-3.2,0.3-4.9,0.6c-3.8,0.6-7.5,1.5-11.2,2.8c-1.5,0.5-3,1.1-4.5,1.7
	h-0.1c-3.5,1.5-6.9,3.3-10.2,5.4l-2.1,1.3L15.1,14.5l1.8-1.1c3.5-2.2,7.1-4.1,10.8-5.7l1.9-0.9l3.7,7.1c1.1-0.4,2.1-0.8,3.2-1.2
	l-1.9-7.8l2-0.7c3.9-1.2,7.8-2.2,11.8-2.9L50.6,1l1.9,7.8L53,8.7c0.9-0.1,1.9-0.2,2.8-0.3v-8L58,0.2c4.1-0.3,8.1-0.3,12.2,0l2.1,0.1
	v8c1.1,0.1,2.2,0.2,3.4,0.4L77.5,1l2.1,0.4c4,0.7,8,1.7,11.8,2.9l2,0.7l-1.9,7.8c1.1,0.4,2.1,0.8,3.2,1.2l3.7-7.1l1.9,0.9
	c3.7,1.7,7.3,3.6,10.8,5.7l1.8,1.1L102.6,34.2z M21.1,16.2l6.1,11.6c2.8-1.7,5.7-3.1,8.7-4.4H36c1.6-0.7,3.2-1.3,4.8-1.8
	c3.9-1.3,7.9-2.4,11.9-3c1.7-0.3,3.4-0.5,5.2-0.6c4.1-0.3,8.2-0.3,12.3,0c1.7,0.2,3.5,0.4,5.2,0.6c4,0.6,8,1.6,11.9,2.9
	c1.6,0.6,3.2,1.2,4.8,1.8c3,1.3,5.9,2.8,8.8,4.4l6.1-11.6c-2.1-1.2-4.3-2.4-6.5-3.4l-3.7,7l-1.9-0.8L94,18.5
	c-1.9-0.8-3.9-1.6-5.9-2.2l-2-0.7L88,7.9c-2.4-0.7-4.7-1.3-7.1-1.8L79,13.9l-2.1-0.3c-2.4-0.4-4.8-0.7-7.1-0.9l-2.1-0.2V4.6
	c-2.4-0.1-4.9-0.1-7.3,0v7.9l-2.1,0.2c-1.6,0.1-3.1,0.3-4.6,0.5c-0.8,0.1-1.7,0.2-2.5,0.4L49,13.9l-1.9-7.7C44.7,6.7,42.3,7.3,40,8
	l1.9,7.7l-2,0.7c-2,0.7-4,1.4-5.9,2.2l-0.8,0.3l-1.9,0.8l-3.7-7C25.4,13.8,23.2,14.9,21.1,16.2L21.1,16.2z"
    />
    <path
      class="oslo-logo"
      d="M64,154c-35.2,0-63.7-28.5-63.7-63.6s28.5-63.7,63.6-63.7s63.7,28.5,63.7,63.6c0,16.9-6.7,33.1-18.6,45.1
	C97.1,147.3,80.9,154,64,154z M64,31.4c-32.6,0-59,26.4-59,58.9s26.4,59,58.9,59c32.6,0,59-26.4,59-58.9c0-15.6-6.2-30.6-17.3-41.7
	C94.6,37.6,79.6,31.4,64,31.4L64,31.4z"
    />
    <path
      class="oslo-logo"
      d="M51.7,72.2c-5-6.7-3.6-16.3,3.1-21.3c6.7-5,16.3-3.6,21.3,3.1c4,5.4,4,12.8,0,18.2l3.4,2.5
	c2.5-3.3,3.9-7.4,3.9-11.6c0-10.7-8.7-19.4-19.4-19.4c-10.7,0-19.4,8.7-19.4,19.4c0,4.2,1.4,8.3,3.9,11.6L51.7,72.2z"
    />
    <path
      class="oslo-logo"
      d="M64,76.9c4.2,0,7.6-3.4,7.6-7.6v-0.8h2.5v-4.8c0-5.6-4.5-10.1-10.1-10.1c-5.6,0-10.1,4.5-10.1,10.1v4.8h2.5v0.8
	C56.4,73.5,59.8,76.9,64,76.9z M58.1,63.7c0-3.3,2.6-5.9,5.9-5.9c3.3,0,5.9,2.6,5.9,5.9c0,0,0,0,0,0v4.8h-1.8v0.8
	c0.1,2.3-1.6,4.2-3.9,4.3c-2.3,0.1-4.2-1.6-4.3-3.9c0-0.1,0-0.3,0-0.4v-0.8h-1.8L58.1,63.7z M20.5,93.2h5.8v5.9h-5.8V93.2z"
    />
    <path
      class="oslo-logo"
      d="M111,88.7v3.1h3l-8.1,8.1V85.8l2.1,2.1l2.2-2.2l-5.9-5.9l-5.9,5.9l2.2,2.2l2.1-2.1V100l-8.1-8.1h2.9v-3.1h-8.3
	v8.4h3.1v-3l6.3,6.4H86l-1.3-1.2l-1.1-10.4c-0.1-0.6-0.7-5.9-5.1-8.2h-0.1l-4.5-1.8L73,78.6l-0.7,0.2c-2.8,0.7-5.6,1-8.4,0.9
	c-2.8,0.1-5.7-0.2-8.4-0.9l-0.7-0.2l-5.2,2h-0.1c-4.4,2.3-5.1,7.6-5.1,8.2l-1.2,10.8l-1,0.8h-4.4c0.4-1.4,0.6-2.8,0.6-4.2
	c0-8.3-6.8-15-15.1-14.9c-8.3,0-15,6.8-14.9,15.1c0,7.4,5.4,13.6,12.7,14.8h0.2c0.4,0.1,0.8,0.1,1.2,0.1l19.5,2
	c-0.7,6.6-2,13.1-3.9,19.5l2,0.7l2,0.6c2-6.7,3.4-13.6,4.1-20.5l4.4-3.7l-0.7,27.2l1.8,0.3c8.2,1.4,16.6,1.4,24.9,0l1.8-0.3
	l-0.7-27.1l4.4,3.7c0.7,6.9,2.1,13.8,4.1,20.5l4-1.3c-1.9-6.4-3.2-12.9-3.9-19.5l19.6-1.8v-6.8l10.4-10.4v3h3.1v-8.3L111,88.7z
	 M64,83.9c3,0.1,6-0.3,9-0.9l3.6,1.4c2.4,1.3,2.8,4.8,2.8,4.8l0.9,8.2h-5.1c-2,0-4.1-0.5-5.8-1.6c-1.3-1-2.5-2.1-3.5-3.4l-7.2-8.8
	C60.5,83.8,62.2,83.9,64,83.9L64,83.9z M23.4,107h-0.5l-1.1-0.1c-5.9-0.9-10-6.5-9-12.4c0.9-5.9,6.5-10,12.4-9
	c5.9,0.9,10,6.5,9,12.4C33.3,103.1,28.8,107,23.4,107L23.4,107z M50.7,104.1l-6,5.1l-12-1.2c1.2-1,2.3-2.1,3.2-3.4h7.9l7-5.9
	L50.7,104.1z M51.1,93.1L51.1,93.1l-3.1,2.6l0.7-6.4v-0.1c0,0,0.3-3.5,2.8-4.8l2-0.8l3.6,4.4L51.1,93.1z M64.1,134.1
	c-3.3,0-6.6-0.2-9.8-0.7l0.2-8.9l0.7-29.4l4.5-3.8l3,3.7c1.3,1.7,2.9,3.2,4.6,4.4c1.8,1.1,3.9,1.8,6,2.1l0.8,31.9
	C70.7,133.8,67.4,134.1,64.1,134.1L64.1,134.1z M101.7,107.5l-18.1,1.7l-9-7.6h6.5l3.4,3h17.2L101.7,107.5z M163.3,87.6
	c0-15.5,9.8-25,21.8-25c12,0,21.8,9.5,21.8,25c0,16.1-9.8,25.7-21.8,25.7S163.3,103.6,163.3,87.6L163.3,87.6z M200.6,87.6
	c0-13.4-6.9-19.4-15.6-19.4s-15.6,6-15.6,19.4s7.2,20.1,15.6,20.1S200.6,101,200.6,87.6z M227.5,76.5c6.6,0,11.4,2.9,13.2,7.6
	l-4.4,2.2c-1.5-3.1-4.7-4.9-9-4.9c-4.5,0-7.6,1.8-7.6,4.8c0,2.6,1.7,4.1,5.5,5l5.1,1c7.7,1.5,11.4,5,11.4,10.3
	c0,6.4-5.6,10.7-13.9,10.7c-7.2,0-12.4-2.8-14.9-8l4.4-2.3c1.8,3.5,5.3,5.5,10.3,5.5s8.5-2.2,8.5-5.6c0-2.9-1.7-4.6-5.8-5.4
	l-6.1-1.2c-6.6-1.3-10.1-4.7-10.1-9.6C214.3,80.9,219.7,76.5,227.5,76.5L227.5,76.5z M259.5,113c-6,0-8.9-3.7-8.9-9.4V63.1h5.8v40.2
	c0,3.1,1.7,4.6,4.5,4.6c0.7,0,1.4-0.1,2.1-0.3v5C261.8,112.9,260.7,113,259.5,113z M267.8,94.8c0-11.1,7.2-18.3,16.3-18.3
	s16.2,7.2,16.2,18.3c0,11.2-7.3,18.5-16.2,18.5S267.8,106,267.8,94.8z M294.4,94.8c0-9-4.7-13.3-10.2-13.3s-10.3,4.2-10.3,13.3
	s4.6,13.5,10.3,13.5S294.4,103.8,294.4,94.8z"
    />
  </svg>
</template>

<script>
export default {
  name: 'OsloLogo',
};
</script>

<style scoped>
.oslo-logo {
  fill: var(--color-text-secondary) !important;
}
</style>
